
import '@/plugins/composition-api'

import { onClickOutside } from '@vueuse/core'
import { computed, defineComponent, ref } from '@vue/composition-api'
import type { TemplateItemType } from '@/use/templates/editor'
import useEditorToolbar from '@/use/templates/editorToolbar'

export default defineComponent({
  props: {
    marginEnabled: { type: Boolean, required: false, default: undefined },
    scaleEnabled: { type: Boolean, required: false, default: undefined },
  },
  setup(props, { emit }) {
    const dynamicDropdown = ref()
    const isDynamicDropdownOpen = ref(false)

    const openDynamicDropdown = () => isDynamicDropdownOpen.value = true
    const closeDynamicDropdown = () => isDynamicDropdownOpen.value = false
    const toggleDynamicDropdown = () => isDynamicDropdownOpen.value ? closeDynamicDropdown() : openDynamicDropdown()

    onClickOutside(dynamicDropdown, closeDynamicDropdown)

    const settingsDropdown = ref()
    const isSettingsDropdownOpen = ref(false)

    const openSettingsDropdown = () => isSettingsDropdownOpen.value = true
    const closeSettingsDropdown = () => isSettingsDropdownOpen.value = false
    const toggleSettingsDropdown = () => isSettingsDropdownOpen.value ? closeSettingsDropdown() : openSettingsDropdown()

    onClickOutside(settingsDropdown, closeSettingsDropdown)

    const { shapes, dynamic } = useEditorToolbar()

    const closeDropdowns = () => {
      closeDynamicDropdown()
      closeSettingsDropdown()
    }

    const create = (type: TemplateItemType) => {
      emit('create', type)
      closeDropdowns()
    }

    const isMarginEnabled = computed(() => !!props.marginEnabled)
    const isScaleEnabled = computed(() => !!props.scaleEnabled)

    const toggleMargin = () => emit('toggle-margin')
    const toggleScale = () => emit('toggle-scale')

    return {
      dynamicDropdown, isDynamicDropdownOpen, openDynamicDropdown, closeDynamicDropdown, toggleDynamicDropdown,
      settingsDropdown, isSettingsDropdownOpen, openSettingsDropdown, closeSettingsDropdown, toggleSettingsDropdown,
      shapes, dynamic,
      create,
      isMarginEnabled, isScaleEnabled, toggleMargin, toggleScale
    }
  }
})
