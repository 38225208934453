import '@/plugins/composition-api'

import type { TemplateItemType } from './editor'

export default function useEditorToolbar() {
  const shapes: { name: string; type: TemplateItemType; icon: string }[] = [
    { name: 'Prostokąt', type: 'Rect', icon: 'mdi-checkbox-blank-outline' },
    { name: 'Koło', type: 'Circle', icon: 'mdi-checkbox-blank-circle-outline' },
    { name: 'Tekst', type: 'Text', icon: 'mdi-text-box-outline' },
    { name: 'Obraz', type: 'Image', icon: 'mdi-image-outline' }
  ]

  const dynamic: { name: string; type: TemplateItemType; icon: string }[] = [
    { name: 'Zdjęcie uczestnika', type: 'ParticipantImage', icon: 'mdi-account-box-outline' },
    { name: 'Kod QR uczestnika', type: 'ParticipantQR', icon: 'mdi-qrcode' },
    { name: 'Tytuł naukowy uczestnika', type: 'ParticipantDegree', icon: 'mdi-school-outline' },
    { name: 'Imię i nazwisko uczestnika', type: 'ParticipantName', icon: 'mdi-pencil-outline' },
    { name: 'Imię uczestnika', type: 'ParticipantFirstName', icon: 'mdi-pencil-outline' },
    { name: 'Nazwisko uczestnika', type: 'ParticipantLastName', icon: 'mdi-pencil-outline' },
    { name: 'Grupa uczestnika', type: 'ParticipantGroup', icon: 'mdi-account-group-outline' },
    { name: 'Instytucja uczestnika', type: 'ParticipantInstitution', icon: 'mdi-city-variant-outline' },
    { name: 'Numer PWZ', type: 'ParticipantLicenseNumber', icon: 'mdi-city-variant-outline' },
    { name: "Liczba punktów", type: "ParticipantPoints", icon: "ic:round-confirmation-number", },
  ]

  return { shapes, dynamic }
}
